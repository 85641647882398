
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment, KfSharedConstantsService, KfAuthService, KfIsearchResultsDataService, KfFilterMetadata, KfIpagedSearchResults, KfStorageService } from '@kf-products-core/kfhub_lib';
import { SpinnerVisibilityService } from 'ng-http-loader';
import * as _ from 'lodash';
import { tap } from 'rxjs/operators';

@Injectable()
export class TalentHubManagementSPService {

    private readonly adminPrefix = '/v2admin/adminuser';
    public config = environment().validationApp;
    private baseUrl = environment().baseApiUrl;
    private relUrl = '/v1/hrms';
    pamsId: any;
    defaultProfileTypePayload = '1,2';

    constructor(
        private authService: KfAuthService,
        private http: HttpClient,
        private constService: KfSharedConstantsService,
        public spinner: SpinnerVisibilityService,
        private storageService: KfStorageService) {
    }

    // tslint:disable-next-line: max-line-length
    public search(searchString: string, filters: KfFilterMetadata[] = [], sorting: any[] = [], pageIndex: number = 0, pageSize: number = 20, getAllSPIds: boolean = false, singleValueFilter: boolean = false, params?: {
        requestClient?: string,
        type?: string,
        sortColumn?: string,
        sortBy?: string,
        searchString?: string,
        searchColumn?: string,
        filterBy?: string,
        filterValues?: string,
        pageIndex?: string,
        pageSize?: string,
        profileType?: string
        }) {
        let profileTypeId = params?.profileType || this.defaultProfileTypePayload;
        if (!singleValueFilter) {
        const selectedClientInfo = JSON.parse(sessionStorage.getItem('selected-client'));
        this.pamsId = Number(selectedClientInfo.PamsID);

        let sortBy: string;
        let sortColumn: string;
        if (sorting.length === 0) {
            sortBy = 'DESC';
            sortColumn = 'MODIFIED_ON';
        } else {
            sortBy = sorting[0].sortBy;
            sortColumn = sorting[0].sortColumn;
        }

        let filterBy = '';
        let filterValues = '';
        let locale='en';
        this.storageService.getItem('localeNewSession') ? locale = this.storageService.getItem('localeNewSession') : locale = locale; 
        if (filters && filters.length > 0) {
            filterValues = locale;
            filters.forEach((filter: any) => {
                if (filter.values.length > 0) {
                    if (!filterBy) {
                        filterBy = filter.id;
                        filterValues = filter.values.join(';');
                    } else {
                        filterBy = filterBy + '|' + filter.id;
                        filterValues = filterValues + '|' + filter.values.join(';');
                    }
                }
            });
        }
        this.spinner.show();
        const url = this.baseUrl + this.relUrl + '/thportal/admin/successprofiles/?';
        const paramsData = {
            requestClient: this.pamsId,
            type: 'SEARCH_SUCCESS_PROFILES',
            sortColumn,
            sortBy,
            searchString,
            searchColumn: 'JOB_TITLE',
            filterBy,
            filterValues,
            pageIndex: pageIndex.toString(),
            pageSize: pageSize.toString(),
            profileType: profileTypeId,
        };
        if (getAllSPIds) {
            paramsData.type = 'SEARCH_SUCCESS_PROFILES_IDS';
        }
        if (searchString == null || searchString.trim() === '') {
            delete paramsData.searchString;
        }
        const params = new HttpParams({ fromObject: paramsData });
        this.spinner.hide();
        return this.authService.authHttpCall('get', url + params.toString());
        } else {
            return this.nestedDropSendAPI(params, getAllSPIds);
        }
    }

    public nestedDropSendAPI(data, getAllSPIds?:boolean) {
        const paramsData = {
            requestClient: this.pamsId,
            type: 'SEARCH_SUCCESS_PROFILES',
            sortColumn: data?.sortColumn,
            sortBy: data?.sortBy,
            searchString: data?.searchString,
            searchColumn: 'JOB_TITLE',
            filterBy: data?.filterBy,
            filterValues: data?.filterValues,
            pageIndex: data?.pageIndex || '1',
            pageSize: data?.pageSize,
            profileType: data?.profileType ||  this.defaultProfileTypePayload,
        }
        if (getAllSPIds) {
            paramsData.type = 'SEARCH_SUCCESS_PROFILES_IDS';
        }
        this.spinner.show();
        const url = this.baseUrl + this.relUrl + '/thportal/admin/successprofiles/?';
        const params = new HttpParams({ fromObject: paramsData });
        this.spinner.hide();
        return this.authService.authHttpCall('get', url + params.toString());
    }

    public getFilters() {
        let locale='en';
        this.storageService.getItem('localeNewSession') ? locale = this.storageService.getItem('localeNewSession') : locale = locale;        
        const selectedClientInfo = JSON.parse(sessionStorage.getItem('selected-client'));
        this.pamsId = Number(selectedClientInfo.PamsID);

        // tslint:disable-next-line: max-line-length
        const url = this.baseUrl + this.relUrl + `/thportal/admin/successprofiles/?requestClient=` + this.pamsId + `&type=METADATA&preferredLocale=${locale}&pageIndex=1&pageSize=2`;
        return this.authService.authHttpCall('get', url, {});
    }

    public getLanguages() {
        const url = this.baseUrl + this.relUrl + '/thportal/admin/languages';

        return this.authService.authHttpCall('get', url, {});
    }
    public getTranslations(categoryId: 'COMPETENCIES' | 'TRAITS' | 'DRIVERS', languageId: string, cmsLanguageId: string) {
        const url = this.baseUrl + this.relUrl + `/thportal/admin/translations?categoryId=${categoryId}&languageId=${languageId}&cmsLanguageId=${cmsLanguageId}`;

        return this.authService.authHttpCall('get', url, {});
    }
    public setTranslations(categoryId: 'COMPETENCIES' | 'TRAITS' | 'DRIVERS', languageId: string, cmsLanguageId: string) {
        const url = this.baseUrl + this.relUrl + `/thportal/admin/translations`;

        return this.authService.authHttpCall('post', url, {
            categoryId,
            languageId,
            cmsLanguageId,
        });
    }

    public exportCustomSP(postData, queryParam) {
        // tslint:disable-next-line: max-line-length
        const url = this.baseUrl + this.relUrl + '/thportal/admin/exportprofiles?loggedInUserClientId=' + queryParam.loggedInUserClientId + '&userId=' + queryParam.userId + '&locale=' + queryParam.locale;
        return this.authService.authHttpCall('post', url, postData);
    }

    public getSubCtgs(locale: string) {
        const url = this.baseUrl + this.relUrl + '/thportal/admin/exportprofiles?loggedInUserClientId=';

        return this.authService.authHttpCall('get', url, {});

    }
}
